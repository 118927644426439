<template>
  <div>
    <step :active="active" />
    <div class="ui_businessMessage">
      <h5>营业信息：</h5>
      <el-form
        :model="businessForm"
        :rules="rules"
        ref="businessForm"
        label-width="150px"
        class="ui_ruleForm"
      >
        <!-- <el-form-item :label="$t('searchModule.Merchant_type')" prop="type" class="ui_ruleForm_item">
          <el-radio-group v-model="businessForm.type">
            <el-radio :label="3">企业/个体商户</el-radio>
            <el-radio :label="6">小微商户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="营业执照" prop="businessType" class="ui_ruleForm_item">
          <el-radio-group v-model="businessForm.businessType">
            <el-radio :label="0">多合一营业执照</el-radio>
            <el-radio :label="1">普通营业执照</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item
          prop="shopLicenseNo"
          label="社会统一信用代码/营业执照号码 "
          class="ui_ruleForm_item"
        >
          <el-input
            v-model="businessForm.shopLicenseNo"
            placeholder="请如实填写社会统一信用代码或营业执照代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="工商名称" prop="shopName" class="ui_ruleForm_item">
          <el-input
            v-model="businessForm.shopName"
            placeholder="请填写营业执照上的名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="营业地址"
          prop="businessAddress"
          class="ui_ruleForm_item"
        >
          <!-- <el-input v-model="businessForm.businessAddress"></el-input> -->
          <el-cascader
            style="width: 400px"
            size="large"
            :options="options"
            v-model="businessForm.businessAddress"
            @change="areaChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
          label="详细地址"
          prop="shopAddrExt"
          class="ui_ruleForm_item"
        >
          <el-input
            v-model="businessForm.shopAddrExt"
            placeholder="例：北京路"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="补充地址" class="ui_ruleForm_item">
          <el-input v-model="businessForm.supplyAddress" placeholder="（选填）请填写剩余说明部分"></el-input>
        </el-form-item>
        <el-form-item label="所属行业" class="ui_ruleForm_item">
          <el-select v-model="businessForm.region" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
          <el-select v-model="businessForm.region" placeholder="请选择" style="margin-left: 20px;">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="" prop="userId">
          <div style="display: block">
            <p>营业执照 (支持jpg/png, 文件 &lt; 2MB)</p>
            <el-upload
              class="avatar-uploader"
              action="/acb/2.0/tenant/cmb/imgUpload"
              :show-file-list="false"
              :on-change="handleChange"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :http-request="uploadPicture"
              :data="{ type: 1 }"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <!-- <span>身份证头像页</span> -->
          </div>
          <div style="display: block">
            <p>营业场所照片 (支持jpg/png, 文件 &lt; 2MB)</p>
            <div style="display: inline-block; position: relative">
              <el-upload
                class="avatar-uploader"
                action="/acb/2.0/tenant/cmb/imgUpload"
                :show-file-list="false"
                :on-change="handleChange2"
                :on-success="handleAvatarSuccess2"
                :before-upload="beforeAvatarUpload"
                :http-request="uploadPicture"
                :data="{ type: 2 }"
              >
                <img v-if="imageUrl2" :src="imageUrl2" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span style="position: absolute; bottom: -20px">门头照片</span>
            </div>
            <div
              style="
                display: inline-block;
                margin-left: 20px;
                position: relative;
              "
            >
              <el-upload
                class="avatar-uploader"
                action="/acb/2.0/tenant/cmb/imgUpload"
                :show-file-list="false"
                :on-change="handleChange3"
                :on-success="handleAvatarSuccess3"
                :before-upload="beforeAvatarUpload"
                :http-request="uploadPicture"
                :data="{ type: 3 }"
              >
                <img v-if="imageUrl3" :src="imageUrl3" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span style="position: absolute; bottom: -20px">店内照片</span>
            </div>
          </div>
          <!-- <div style="display: block; margin-top: 20px;">
            <p>辅助证明材料 (选填, 支持jpg/png/bmp, 文件 &lt; 2MB)</p>
            <p style="line-height: 0px; margin-bottom: 20px; color: rgb(144 138 138);">包括但不限于营业场所租赁协议或产权证明, 集中经营场所管理方出具的证明文件等能够反应小微商户真实,合法从事商品或服务交易活动的材料</p>
            <div style="display: inline-block; position: relative;">
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :on-change="handleChange"
                :on-success="handleAvatarSuccess"
                >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span style="position: absolute; bottom: -20px;">辅助证明材料</span>
            </div>
            <div style="display: inline-block; margin-left: 20px; position: relative;">
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :on-change="handleChange"
                :on-success="handleAvatarSuccess"
                >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span style="position: absolute; bottom: -20px;">辅助证明材料</span>
            </div>
            <div style="display: inline-block; margin-left: 20px; position: relative;">
              <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :on-change="handleChange"
                :on-success="handleAvatarSuccess"
                >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span style="position: absolute; bottom: -20px;">辅助证明材料</span>
            </div>
          </div> -->
          <!-- <div style="margin-top: 30px">
            <p>
              <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
              <span style="color: #0f6eff; cursor: pointer"
                >《AIPARK爱泊车商户自助签约知情同意书》</span
              >
            </p>
          </div> -->
        </el-form-item>
        <!-- <div>
          <p>身份证正反面照片 (支持jpg/png/bmp, 文件 &lt; 2MB)</p>
        </div> -->
        <!-- <el-form-item label="身份证正反面照片 (支持jpg/png/bmp, 文件 < 2MB)" prop="userId">
          <el-input v-model="businessForm.userId"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="$router.push('/realName?tenantId=' + tenantId)"
            >上一步</el-button
          >
          <el-button type="primary" @click="submitForm('businessForm')"
            >下一步</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
import step from "./step";
export default {
  components: { step },
  data() {
    let checkDetailsAddress = (rule, value, callback) => {
      if (
        this.businessForm.way.trim().length < 1 ||
        this.businessForm.number.trim().length < 1
      ) {
        callback("请输入详细地址");
      } else {
        callback();
      }
    };
    return {
      options: regionData,
      selectedOptions: [],
      radio: "1",
      checked: false,
      imageUrl: "",
      imageUrl2: "",
      imageUrl3: "",
      active: 1,
      businessForm: {
        shopName: "",
        businessAddress: "",
        shopAddrExt: "",
        shopLicenseNo: "",
      },
      rules: {
        shopLicenseNo: [
          {
            required: true,
            message: "请如实填写社会统一信用代码或营业执照代码",
            trigger: "blur",
          },
        ],
        shopName: [
          { required: true, message: "请选择商户类型名称", trigger: "blur" },
        ],
        businessName: [
          { required: true, message: "请选择工商名称", trigger: "blur" },
        ],
        businessAddress: [
          { required: true, message: "请选择营业地址", trigger: "blur" },
        ],
        shopAddrExt: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.tenantId = this.$route.query.tenantId;
    this.imgOptions = {
      tenantId: this.tenantId,
    };
    this.$axios
      .get("/acb/2.0/tenant/cmb/mechantShop/byTenantId/" + this.tenantId)
      .then((res) => {
        if (res.state != 0) {
        } else {
          this.businessForm = {
            shopName: res.value.shopName,
            businessAddress: [
              res.value.shopProvinceId + '0000',
              res.value.shopCityId,
              res.value.shopCountyId,
            ],
            shopAddrExt: res.value.shopAddrExt,
            shopLicenseNo: res.value.shopLicenseNo,
          };
          if (res.value.mechantPictureList.length > 0) {
            this.imageUrl = res.value.mechantPictureList[0].pictureOssUrl;
            this.imageUrl2 = res.value.mechantPictureList[1].pictureOssUrl;
            this.imageUrl3 = res.value.mechantPictureList[1].pictureOssUrl;
          }
        }
      });
  },
  methods: {
    // 上传图片
    uploadPicture(data) {
      const file = new FormData();
      const image = new FormData();
      image.append("tenantId", this.tenantId);
      if (data.data.type == 1) {
        image.append("imgType", "0002");
      } else if (data.data.type == 2) {
        image.append("imgType", "0005");
      } else {
        image.append("imgType", "0015");
      }
      image.append("step", 2);
      image.append("image", data.file);
      const opt = {
        method: "post",
        url: data.action,
        data: image,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        success: (res) => {
        },
        fail: (res) => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    areaChange(value) {
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // if (!this.checked) {
          //   this.$alert(
          //     "请先阅读并同意《AIPARK爱泊车商户自助签约知情同意书》",
          //     this.$t('pop_up.Tips'),
          //     {
          //       confirmButtonText: this.$t('pop_up.Determine'),
          //     }
          //   );
          // }
          console.log("submit!!");
          this.$axios
            .post("/acb/2.0/tenant/cmb/saveOrUpdateShop", {
              data: {
                tenantId: this.tenantId,
                shopLicenseNo: this.businessForm.shopLicenseNo,
                shopName: this.businessForm.shopName,
                shopProvinceId: this.businessForm.businessAddress[0].substring(0, 2),
                shopCityId: this.businessForm.businessAddress[1],
                shopCountyId: this.businessForm.businessAddress[2],
                legalIdCardNo: this.businessForm.legalIdCardNo,
                shopAddrExt: this.businessForm.shopAddrExt,
                mccCode: "7523",
              },
            })
            .then((res) => {
              if (res.state != 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              } else {
                this.$router.push({
                  path: "/accountMessage",
                  query: {
                    tenantId: this.tenantId,
                  },
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleChange(file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess2(res, file) {
      this.imageUrl2 = URL.createObjectURL(file.raw);
    },
    handleChange2(file) {
      this.imageUrl2 = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess3(res, file) {
      this.imageUrl3 = URL.createObjectURL(file.raw);
    },
    handleChange3(file) {
      this.imageUrl3 = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isJpg = suffix === ('jpg' || 'jpeg' || 'JPG' || 'JPEG')
      const isPng = suffix === ('png' || 'PNG')
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJpg  && !isPng) {
        this.$message.warning('图片格式错误，只支持jpg和png格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.warning('上传图片大小不能超过 2M!')
        return false
      }
      return true
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.ui_businessMessage {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;

  >h5 {
    margin-bottom: 20px;
    font-weight: 400;
  }
}

.ui_ruleForm_item {
  width: 50%;
}

.avatar-uploader /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader /deep/ .el-upload:hover {
  border-color: #0f6eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.ui_realname_iphone {
  display: flex;
  justify-content: space-between;
}

.ui_detailsAddress {
  display: flex;
  justify-content: space-between;

  >div {
    width: 50%;

    >span {
      margin-left: 10px;
    }
  }

  /deep/ .el-input {
    width: 70%;
  }
}
</style>
